var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiLoadingController", {
    staticClass: "map-wrapper",
    attrs: { fetch: _vm.fetch, params: _vm.params },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          return [
            _c(
              "MapController",
              _vm._b(
                {
                  attrs: {
                    notes: _vm.bookmarks,
                    "initial-state": _vm.initialState
                  },
                  on: { "update:initialState": _vm.updateInitialState }
                },
                "MapController",
                data,
                false
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }